import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Contactsection from './Contactsection'
import Subscribe from './subscribe'



function Schedule() {
  return (
    <div>
            <Header />
                  {/* <!-- Breadcrumb Section Begin --> */}
    <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-text">
                        <h2>Event Services</h2>
                        <div class="bt-option">
                            <a href="#">Home</a>
                            <span>Our Schedule</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Breadcrumb Section End --> */}

    {/* <!-- Schedule Table Section Begin --> */}
    <section class="schedule-table-section spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="schedule-table-tab">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Wedding Planning</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Government Events</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Corporate Events</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-4" role="tab">Birthday Party</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-5" role="tab">Other Event</a>
                            </li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div class="tab-content">
                            <div class="tab-pane active" id="tabs-1" role="tabpanel">
                                <div class="schedule-table-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <strong>Day 1</strong>
                                                    <span>May 09, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 2</strong>
                                                    <span>May 11, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 3</strong>
                                                    <span>May 12, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 4</strong>
                                                    <span>May 14, 2019</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="event-time">10:00</td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">11:00</td>
                                                <td class="hover-bg">
                                                    <h5>Talking To Life</h5>
                                                    <p><span>By</span> John Smith</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Delia Mullins</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Introduce Technology</h5>
                                                    <p><span>By</span> Bryan Clark</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Marketing Design</h5>
                                                    <p><span>By</span> Ida Manning</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">13:30</td>
                                                <td class="hover-bg">
                                                    <h5>Meet Up Marketing</h5>
                                                    <p><span>By</span> Mc Cage</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Conect People</h5>
                                                    <p><span>By</span> James Colon</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Maximize CEO</h5>
                                                    <p><span>By</span> Elmer Dunn</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Martha Fuller</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">18:00</td>
                                                <td class="hover-bg">
                                                    <h5>Technology Future</h5>
                                                    <p><span>By</span> Thomas Munoz</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Mattie Hanson</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Camping</h5>
                                                    <p><span>By</span> Rachel Arnold</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Leonard Daniels</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane" id="tabs-2" role="tabpanel">
                                <div class="schedule-table-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <strong>Day 1</strong>
                                                    <span>May 09, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 2</strong>
                                                    <span>May 11, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 3</strong>
                                                    <span>May 12, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 4</strong>
                                                    <span>May 14, 2019</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="event-time">15:00</td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">16:00</td>
                                                <td class="hover-bg">
                                                    <h5>Talking To Life</h5>
                                                    <p><span>By</span> John Smith</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Delia Mullins</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Introduce Technology</h5>
                                                    <p><span>By</span> Bryan Clark</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Marketing Design</h5>
                                                    <p><span>By</span> Ida Manning</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">17:30</td>
                                                <td class="hover-bg">
                                                    <h5>Meet Up Marketing</h5>
                                                    <p><span>By</span> Mc Cage</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Conect People</h5>
                                                    <p><span>By</span> James Colon</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Maximize CEO</h5>
                                                    <p><span>By</span> Elmer Dunn</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Martha Fuller</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">18:00</td>
                                                <td class="hover-bg">
                                                    <h5>Technology Future</h5>
                                                    <p><span>By</span> Thomas Munoz</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Mattie Hanson</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Camping</h5>
                                                    <p><span>By</span> Rachel Arnold</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Leonard Daniels</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane" id="tabs-3" role="tabpanel">
                                <div class="schedule-table-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <strong>Day 1</strong>
                                                    <span>May 09, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 2</strong>
                                                    <span>May 11, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 3</strong>
                                                    <span>May 12, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 4</strong>
                                                    <span>May 14, 2019</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="event-time">16:00</td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">17:00</td>
                                                <td class="hover-bg">
                                                    <h5>Talking To Life</h5>
                                                    <p><span>By</span> John Smith</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Delia Mullins</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Introduce Technology</h5>
                                                    <p><span>By</span> Bryan Clark</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Marketing Design</h5>
                                                    <p><span>By</span> Ida Manning</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">18:30</td>
                                                <td class="hover-bg">
                                                    <h5>Meet Up Marketing</h5>
                                                    <p><span>By</span> Mc Cage</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Conect People</h5>
                                                    <p><span>By</span> James Colon</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Maximize CEO</h5>
                                                    <p><span>By</span> Elmer Dunn</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Martha Fuller</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">19:00</td>
                                                <td class="hover-bg">
                                                    <h5>Technology Future</h5>
                                                    <p><span>By</span> Thomas Munoz</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Mattie Hanson</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Camping</h5>
                                                    <p><span>By</span> Rachel Arnold</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Leonard Daniels</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane" id="tabs-4" role="tabpanel">
                                <div class="schedule-table-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <strong>Day 1</strong>
                                                    <span>May 09, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 2</strong>
                                                    <span>May 11, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 3</strong>
                                                    <span>May 12, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 4</strong>
                                                    <span>May 14, 2019</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="event-time">09:00</td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">10:00</td>
                                                <td class="hover-bg">
                                                    <h5>Talking To Life</h5>
                                                    <p><span>By</span> John Smith</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Delia Mullins</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Introduce Technology</h5>
                                                    <p><span>By</span> Bryan Clark</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Marketing Design</h5>
                                                    <p><span>By</span> Ida Manning</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">13:30</td>
                                                <td class="hover-bg">
                                                    <h5>Meet Up Marketing</h5>
                                                    <p><span>By</span> Mc Cage</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Conect People</h5>
                                                    <p><span>By</span> James Colon</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Maximize CEO</h5>
                                                    <p><span>By</span> Elmer Dunn</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Martha Fuller</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">14:00</td>
                                                <td class="hover-bg">
                                                    <h5>Technology Future</h5>
                                                    <p><span>By</span> Thomas Munoz</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Mattie Hanson</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Camping</h5>
                                                    <p><span>By</span> Rachel Arnold</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Leonard Daniels</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane" id="tabs-5" role="tabpanel">
                                <div class="schedule-table-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <strong>Day 1</strong>
                                                    <span>May 09, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 2</strong>
                                                    <span>May 11, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 3</strong>
                                                    <span>May 12, 2019</span>
                                                </th>
                                                <th>
                                                    <strong>Day 4</strong>
                                                    <span>May 14, 2019</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="event-time">20:00</td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                                <td class="break hover-bg">
                                                    <h5>Coffee Break</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">22:00</td>
                                                <td class="hover-bg">
                                                    <h5>Talking To Life</h5>
                                                    <p><span>By</span> John Smith</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Delia Mullins</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Introduce Technology</h5>
                                                    <p><span>By</span> Bryan Clark</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Marketing Design</h5>
                                                    <p><span>By</span> Ida Manning</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">23:30</td>
                                                <td class="hover-bg">
                                                    <h5>Meet Up Marketing</h5>
                                                    <p><span>By</span> Mc Cage</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Conect People</h5>
                                                    <p><span>By</span> James Colon</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Maximize CEO</h5>
                                                    <p><span>By</span> Elmer Dunn</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Martha Fuller</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="event-time">24:00</td>
                                                <td class="hover-bg">
                                                    <h5>Technology Future</h5>
                                                    <p><span>By</span> Thomas Munoz</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Reinventing Experiences</h5>
                                                    <p><span>By</span> Mattie Hanson</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Camping</h5>
                                                    <p><span>By</span> Rachel Arnold</p>
                                                </td>
                                                <td class="hover-bg">
                                                    <h5>Social Website</h5>
                                                    <p><span>By</span> Leonard Daniels</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Schedule Table Section End --> */}

    {/* <!-- Newslatter Section Begin --> */}
    {/* <section class="newslatter-section about-newslatter">
        <div class="container">
            <div class="newslatter-inner set-bg" data-setbg="img/newslatter-bg.jpg">
                <div class="ni-text">
                    <h3>Subscribe Newsletter</h3>
                    <p>Subscribe to our newsletter and don’t miss anything</p>
                </div>
                <form action="#" class="ni-form">
                    <input type="text" placeholder="Your email" />
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </section> */}
    <Subscribe />
    {/* <!-- Newslatter Section End --> */}

   <Contactsection />
    <Footer />
    </div>
  )
}

export default Schedule