import React from 'react'

function Contactsection() {
  return (
    <div>
                   {/* <!-- Contact Section Begin --> */}
    <section class="contact-section spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="section-title">
                        <h2>Location</h2>
                        <p>Get directions to our event center</p>
                    </div>
                    <div class="cs-text">
                        <div class="ct-address">
                            <span>Address:</span>
                            <p>Kailashpuri, Ramgarhmode, Indrapuri, Parasrampuri, 302002 <br />Jaipur, Rajasthan, India</p>
                        </div>
                        <ul>
                            <li>
                                <span>Phone:</span>
                                (+91)-7014975578 <br />
                            </li>
                            <li>
                                <span>Email:</span>
                                info.colorlib@gmail.com
                            </li>
                        </ul>
                        <div class="ct-links">
                            <span>Website:</span>
                            <p>https://conference.colorlib.com</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="cs-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d14226.313414289552!2d75.842675772229!3d26.948578479523302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDU2JzU1LjMiTiA3NcKwNTAnMTcuMCJF!5e0!3m2!1sen!2sin!4v1703607655944!5m2!1sen!2sin"
                            height="400" style={{border:"0"}} allowfullscreen=""></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Contact Section End --> */}
    </div>
  )
}

export default Contactsection