import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
             {/* <!-- Footer Section Begin --> */}
    <footer class="footer-section">
        <div class="container">
            <div class="partner-logo owl-carousel">
                <a href="#" class="pl-table">
                    <div class="pl-tablecell">
                        <img src="assets/img/partner-logo/logo-1.png" alt="" />
                    </div>
                </a>
                <a href="#" class="pl-table">
                    <div class="pl-tablecell">
                        <img src="assets/img/partner-logo/logo-2.png" alt="" />
                    </div>
                </a>
                <a href="#" class="pl-table">
                    <div class="pl-tablecell">
                        <img src="assets/img/partner-logo/logo-3.png" alt="" />
                    </div>
                </a>
                <a href="#" class="pl-table">
                    <div class="pl-tablecell">
                        <img src="assets/img/partner-logo/logo-4.png" alt="" />
                    </div>
                </a>
                <a href="#" class="pl-table">
                    <div class="pl-tablecell">
                        <img src="assets/img/partner-logo/logo-5.png" alt="" />
                    </div>
                </a>
                <a href="#" class="pl-table">
                    <div class="pl-tablecell">
                        <img src="assets/img/partner-logo/logo-6.png" alt="" />
                    </div>
                </a>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-text">
                        <div class="ft-logo">
                            <a href="#" class="footer-logo"><img src="assets/img/footer-logo.png" alt="" /></a>
                        </div>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/speaker">Speakers</Link></li>
                            <li><Link to="/schedule">Schedule</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                        <div class="copyright-text"><p>
                            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
  Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i class="fa fa-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>
  {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
  </p></div>
                        <div class="ft-social">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            {/* <a href="#"><i class="fa fa-twitter"></i></a> */}
                            {/* <a href="#"><i class="fa fa-linkedin"></i></a> */}
                            <a href="https://www.instagram.com/impulse_professional_events001?igsh=MXQ5b3o1OHVnZ3l2bg=="><i class="fa fa-instagram"></i></a>
                            {/* <a href="#"><i class="fa fa-youtube-play"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    {/* <!-- Footer Section End --> */}
    </div>
  )
}

export default Footer