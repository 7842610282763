import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './component/Index';
import Aboutus from './component/Aboutus';
import Blog from './component/Blog';
import Blogdetails from './component/Blogdetails';
import Contact from './component/Contact';
import Schedule from './component/Schedule';
import Speaker from './component/Speaker';



function App() {
  return (
    <BrowserRouter>
    <Routes>
      
        <Route path="/" element={<Index />} />
        <Route path="contact" element={<Contact />} />
        <Route path="aboutus" element={<Aboutus />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blogdetails" element={<Blogdetails />} />
        <Route path="schedule" element={<Schedule />} />
        <Route path="speaker" element={<Speaker />} />
     
    </Routes>
  </BrowserRouter>
  );
}

export default App;
