import React from 'react'

function subscribe() {
    const stylenewslatter = {
        backgroundImage: `url(assets/img/newslatter-bg.jpg)`,
      };
  return (
    <div>
                   {/* <!-- Newslatter Section Begin --> */}
    <section class="newslatter-section">
        <div class="container">
            <div class="newslatter-inner set-bg" style={stylenewslatter}>
                <div class="ni-text">
                    <h3>Subscribe Newsletter</h3>
                    <p>Subscribe to our newsletter and don’t miss anything</p>
                </div>
                <form action="#" class="ni-form">
                    <input type="text" placeholder="Your email" />
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </section>
    {/* <!-- Newslatter Section End --> */}
    </div>
  )
}

export default subscribe