import React from 'react'
import { Link } from 'react-router-dom'


function Header() {
  return (
    <div>
               {/* <!-- Page Preloder --> */}
    {/* <div id="preloder">
        <div class="loader"></div>
    </div> */}

    {/* <!-- Header Section Begin --> */}
    <header class="header-section">
        <div class="container">
            <div class="logo">
                <Link to="/">
                    <img src="assets/img/logo.png" alt="" />
                </Link>
            </div>
            <div class="nav-menu">
                <nav class="mainmenu mobile-menu">
                    <ul>
                        <li class="active"><Link to="/">Home</Link></li>
                        <li><Link to="/aboutus">About</Link></li>
                        <li><Link to="/speaker">Speakers</Link>
                            <ul class="dropdown">
                                <li><Link to="#">Jayden</Link></li>
                                <li><Link to="#">Sara</Link></li>
                                <li><Link to="#">Emma</Link></li>
                                <li><Link to="#">Harriet</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/schedule">Schedule</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/contact">Contacts</Link></li>
                    </ul>
                </nav>
                <Link to="#" class="primary-btn top-btn"><i class="fa fa-ticket"></i> Ticket</Link>
            </div>
            <div id="mobile-menu-wrap"></div>
        </div>
    </header>
    {/* <!-- Header End --> */}
    </div>
  )
}

export default Header